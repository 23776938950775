import { Button, QueryLoader, Struct, Title } from 'components';
import OfferDateButtons from 'components/OfferDateButtons';
import OfferPublishToggle from 'feature/OfferPublishToggle';
import { loader } from 'graphql.macro';
import { QueryResult } from 'localTypes';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OfferState, filterOffersByDate } from 'services/offerService';
import { getNodes } from 'services/queryService';
import { getPlannedOffers_offers_edges_node_OfferTemplate_plannedOffers } from 'types/getPlannedOffers';
import {
    getTableServiceOfferTemplates,
    getTableServiceOfferTemplates_list_edges_node_TableServiceOfferTemplate as TableServiceOfferTemplatesEdge,
    getTableServiceOfferTemplates_ArticleCertifications_edges_node_ArticleCertification as ArticleCertificationsEdge,
    getTableServiceOfferTemplates_ArticleTags_edges_node_ArticleTag as ArticleTagsEdge,
} from 'types/getTableServiceOfferTemplates';
import { RouteComponentProps } from 'react-router-dom';
import OfferItemsTable from './OfferItemsTable';
import EnqueueOscarImportButton from 'feature/EnqueueOscarImport';
import { CheckOfferValidModal, TArticleErrorLabels } from 'components/Modal';
import { withApollo } from '@apollo/client/react/hoc';
import { client } from 'services/apollo/client';
import { validateOfferWithCashSystem } from 'types/validateOfferWithCashSystem';
import { ButtonType } from 'components/Button';

const DATE_COUNT = 5;

const OFFER_TEMPLATES_LIST_QUERY = loader('./query/getTableServiceOfferTemplates.gql');
//bla
const OFFER_CHECK_QUERY = loader('../../../feature/CheckOffer/validateOfferWithCashSystem.gql');

export interface IOffer extends getPlannedOffers_offers_edges_node_OfferTemplate_plannedOffers {
    state: OfferState;
}

const today = new Date();
const firstDate = new Date();
const lastDate = new Date();
lastDate.setDate(today.getDate() + DATE_COUNT);
const OFFER_ITEM_LIST_QUERY = loader('./query/listTableServiceOfferItem.gql');

const offerQuerySearch = [
    { key: 'orderRange', operator: '>', value: firstDate.toISOString() },
    { key: 'orderRange', operator: '<', value: lastDate.toISOString() },
];

const DailyOffersPage = ({
    match: {
        params: { idHolding, idPos },
    }
}: RouteComponentProps<{ idHolding: string, idPos: string }>) => {
    const { t } = useTranslation();
    const [activeDate, setActiveDate] = useState(today);

    const [isCatalogPanelOpen, setCatalogPanelOpen] = useState(false);
    const toggleCatalogModal = () => {
        setCatalogPanelOpen(!isCatalogPanelOpen);
    };

    const [testState, setTestState] = useState<{ articleErrorLabels: TArticleErrorLabels}>({ articleErrorLabels : []});

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCheckOfferLoading, setIsCheckOfferLoading] = useState(false);
    const [isItemsEmpty, setIsItemsEmpty] = useState(false);


    const getOfferValidity = async (offerId : string, showModal = false) => {

        
        let items : TArticleErrorLabels = []
        
        try {
            setIsCheckOfferLoading(true);
            const res = await client.query<validateOfferWithCashSystem>({query : OFFER_CHECK_QUERY, variables : {idOffer : offerId}, fetchPolicy : 'no-cache'});
            
            items = res.data.validateOfferWithCashSystem.offerItemsNotOpenForReservation
            .map(i => ({id: i.id, cashSystemCode : i.localArticle.cashSystemCode  , label : i.label}));
            setTestState({ articleErrorLabels : items });
            if(items.length)
                showModal = true

            setIsModalOpen(showModal);

        } catch {

        }
        setIsCheckOfferLoading(false);

        return items

    }

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen)
    }

    const checkItemsList = async (offerId : string) => {
        await client.query<any>({
            query: OFFER_ITEM_LIST_QUERY,
            variables: {
                after: null,
                before: null,
                first: 100,
                last: null,
                querySearch: [{ key: 'idOffer', operator: '=', value: offerId },{ key: 'toSell', operator: '=', value: "ACTIVATED" }],
            },
            fetchPolicy: 'no-cache',
        })
        .then((res: any) => {
            setIsItemsEmpty(res.data.list.totalCount === 0);
        });
     }


    return (
        <QueryLoader
            hasData={(data: getTableServiceOfferTemplates): boolean => !!(data && data.list)}
            query={OFFER_TEMPLATES_LIST_QUERY}
            variables={{ offerQuerySearch, idHolding, idPos }}
            fetchPolicy={"cache-and-network"}
        >
            {({ data }: QueryResult<getTableServiceOfferTemplates>) => {
                const offerTemplates = getNodes(data.list) as TableServiceOfferTemplatesEdge[];

                // no offer available for the POS
                if (offerTemplates.length === 0) return null;

                // The first offer template is taken arbitrarily
                const activeOfferTemplateOffers = offerTemplates[0].offers;
                const activeOffers = filterOffersByDate(activeOfferTemplateOffers, activeDate);

                if (activeOffers.length > 0) {
                    checkItemsList(activeOffers[0].id);
                }
                
                return (
                    <Struct.Section>
                        <CheckOfferValidModal
                            toggleModal={toggleModal}
                            open={isModalOpen}
                            articleErrorLabels={testState.articleErrorLabels}
                        />
                        <OfferDateButtons
                            onSelectDate={setActiveDate}
                            offers={activeOfferTemplateOffers}
                            activeDate={activeDate}
                            firstDate={firstDate}
                            dateCount={DATE_COUNT}
                        >
                            {activeOffers[0] && (
                                <OfferPublishToggle
                                    offer={activeOffers[0]}
                                    preCheckOffer={() => {
                                        return getOfferValidity(activeOffers[0].id, false);
                                    }}
                                />
                            )}
                        </OfferDateButtons>
                        {activeOffers[0] && (
                            <>
                                <Struct.Card>
                                    <Title mode="H3" value={t('page:clickcollect.daily-offers.title.articles')} grow>
                                        <Button
                                            display={ButtonType.SECONDARY}
                                            disabled={isCheckOfferLoading || isItemsEmpty}
                                            onClick={() => getOfferValidity(activeOffers[0].id, true)}
                                        >
                                            {t('app:button.check-offer-valid')}
                                        </Button>
                                        <EnqueueOscarImportButton posId={idPos} />
                                        <Button onClick={toggleCatalogModal}>{t('app:button.edit-articles')}</Button>
                                    </Title>
                                    <OfferItemsTable
                                        defaultQuerySearch={[{ key: 'idOffer', value: activeOffers[0].id }]}
                                        articleTags={getNodes(data.ArticleTags) as ArticleTagsEdge[]}
                                        articleLabels={
                                            getNodes(data.ArticleCertifications) as ArticleCertificationsEdge[]
                                        }
                                        toggleCatalogModal={toggleCatalogModal}
                                        isCatalogPanelOpen={isCatalogPanelOpen}
                                        checkItemsList={checkItemsList}
                                    />
                                </Struct.Card>
                            </>
                        )}
                        {!activeOffers[0] && (
                            <Title mode="H3" value={t('page:clickcollect.daily-offers.title.no-offer')} />
                        )}
                    </Struct.Section>
                );
            }}
        </QueryLoader>
    );
};

// @ts-ignore
export default withApollo(DailyOffersPage);
