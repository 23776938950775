import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { loader } from 'graphql.macro';
import { Duration, DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import { BackButton, Button, Icon, Struct, Title, Loader, Tooltip } from 'components';
import OfferDatesCarousel from 'components/OfferDatesCarousel';
import { PaginationOptions } from 'localTypes';
import OfferPublishToggle from 'feature/OfferPublishToggle';
import OfferInfo from 'pages/ClickCollect/components/OfferInfo';
import { getDates, getDayFullName, getDisplayDate, getDisplayTime } from 'services/dateService';
import { getItem, LocalStorageKey, removeItem } from 'services/persistentData';
import { getPlannedAndUnavailableOffers } from 'services/offerService';
import { getOffer_offer_Offer } from 'types/getOffer';
import OfferItemTable from './OfferItemTable';
import OfferParameterFormPanel from './OfferParameterFormPanel';
import { OfferTemplateWithdrawalType } from '../../../../types/globalTypes';
import { Arrow } from "../../../Communication/AddOrEditForm/components/ComponentHeader";
import { toast } from 'react-toastify';
import { ErrorToast } from '../../../../components/ErrorToast';
import { decodeEntityId, extractFilenameFromHeader, triggerBrowserFileDownload } from '../../../../utils';
import OfferItemsCloner from './OfferItemsCloner';
import ApplyOfferItemsToAll from './ApplyOfferItemsToAll';
import { getCarouselFirstDay, getDatesIntervalISO } from './utils';
import { validateOfferWithCashSystem } from 'types/validateOfferWithCashSystem';
import { client } from 'services/apollo/client';
import { ButtonType } from 'components/Button';
import { TArticleErrorLabels } from 'components/Modal';
import { FRANCE_TIMEZONE } from 'utils';
import getAppConfiguration  from 'services/conf';

const GET_OFFER_QUERY = loader('../query/getOffer.gql');
const GET_PLANNED_OFFERS_V2_QUERY = loader('../query/getPlannedOffersV2FromOfferTemplate.gql');
const CREATE_OFFER_FROM_TEMPLATE_MUTATION = loader('../../DailyOffersPage/query/createOfferFromTemplate.gql');
const REPORTS_AVAILABLE_FOR_DOWNLOAD = loader('../../DailyOffersPage/query/reportsAvailableForDownload.gql');
const OFFER_CHECK_QUERY = loader(
  "../../../../feature/CheckOffer/validateOfferWithCashSystem.gql",
);
const OFFER_ITEM_LIST_QUERY = loader('../query/listOfferItems.gql');


const DEFAULT_NUMB_OF_DAYS_DISPLAYED = 5;
const DAYS_TO_FETCH_WHEN_GOING_BACK = 6;

interface IProps {
    theme: DefaultTheme;
    match: {
        params: { idOffer: string; idHolding: string; idPos: string };
    };
}

export enum ReportsForPickupPoints {
    CUMULATIVE = 'CUMULATIVE',
    UNIT= 'UNIT',
    DELIVERY = 'DELIVERY'
}

export enum ReportForRoomService {
  CUMULATIVE = 'CUMULATIVE',
  UNIT= 'UNIT',
  TOTAL= 'TOTAL',
}

export enum Report {
  CUMULATIVE = 'CUMULATIVE',
  UNIT= 'UNIT'
}

const CashSystemIds = {
    "1" : 'CSI',
    "2" : 'JES',
    "3" : 'ORACLE'
}

function OfferDetailsPage(props: IProps & RouteComponentProps) {
    const {
        theme,
        history,
        match: {
            params: { idOffer, idHolding, idPos },
        },
    } = props;

    
    
    const { t } = useTranslation();
    const [carouselFirstDay, setCarouselFirstDay] = useState(new Date());
    const [didChangeFirstDay, setDidChangeFirstDay] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [isCatalogPanelOpen, setCatalogPanelOpen] = useState(false);
    const [isOfferPanelOpen, setOfferPanelOpen] = useState(false);
    const [formulasCatalog, setFormulasCatalog] = useState<{ idOfferItemFormula: string; stepNumber: number; stepArticles; } | null>(null);
    const [selectedOffer, setSelectedOffer] = useState({ id: '' });
    const [shouldRefetchItems, setShouldRefetchItems] = useState(false);
    const [shouldVerifyOfferArticles, setShouldVerifyOfferArticles] = useState(false);
    const [isItemsEmpty, setIsItemsEmpty] = useState(false);
    

    
    getAppConfiguration('buttonTestCashSystem').then((conf) => {
        const currentCashSystem = Number(getItem(LocalStorageKey.CASH_SYSTEM_ID)) ?? -1;
        const authorizedCashSystems = conf.cashSystems;
        if (!authorizedCashSystems.includes(CashSystemIds[currentCashSystem]))
            return;

        setShouldVerifyOfferArticles(true);
    });

    const defaultInterval = getDatesIntervalISO(carouselFirstDay);
    const [plannedOffersPagination, setPlannedOffersPagination] = useState<PaginationOptions>({
        first: DEFAULT_NUMB_OF_DAYS_DISPLAYED,
        limitDateISO: defaultInterval,
    });

    const [handleExcelReportButtonDebounce, setHandleExcelReportButtonDebounce] = useState<number | null>(null);

    const { loading, data } = useQuery(GET_OFFER_QUERY, {
        variables: { id: idOffer },
    });
    
    const currentOffer = data && (data.offer as getOffer_offer_Offer);
    const offerTemplate = currentOffer?.offerTemplate;
    const [withdrawStart, withdrawEnd] = currentOffer ? getDates(currentOffer?.withdrawRange) : ['', ''];
    const [orderStart, orderEnd] = currentOffer ? getDates(currentOffer?.orderRange) : ['', ''];
    const withdrawSlotDuration =
    currentOffer && currentOffer?.withdrawSlotDuration
    ? currentOffer?.withdrawSlotDuration
    : offerTemplate?.withdrawSlotDuration;
    
    const [createOfferFromTemplate] = useMutation(CREATE_OFFER_FROM_TEMPLATE_MUTATION);

    const [showValidationArticle, toggleValidationArticle] = useState(false);
    const [articleErrorState, setArticleErrorLabelsState] = useState<{
      items: TArticleErrorLabels;
      isLoading: boolean;
    }>({
      items: [],
      isLoading: false,
    });
    const {
        loading: plannedOffersLoading,
        data: plannedOffersData,
        refetch: plannedOffersRefetch,
    } = useQuery(GET_PLANNED_OFFERS_V2_QUERY, {
        variables: {
            idOfferTemplate: offerTemplate?.id,
            ...plannedOffersPagination,
        },
        fetchPolicy: 'network-only',
        skip: !offerTemplate,
    });
    

    const hasNextPage = plannedOffersData?.offerTemplate?.plannedOffers_v2?.pageInfo?.hasNextPage;
    const hasPreviousPage = plannedOffersData?.offerTemplate?.plannedOffers_v2?.pageInfo?.hasPreviousPage;
    const plannedOffers = plannedOffersData?.offerTemplate?.plannedOffers_v2?.edges?.map(({ node }) => node);
    const isDaysInAdvance = plannedOffersData?.offerTemplate?.daysInAdvanceEnd > 0;
    const isRoomService = !!plannedOffersData?.offerTemplate?.isRoomService;

    const refreshItemsAndPlannedOffers = useCallback(async () => {
        if (!!idOffer && !!offerTemplate?.id && isRoomService) {
            plannedOffersRefetch({
                first: DEFAULT_NUMB_OF_DAYS_DISPLAYED,
                idOfferTemplate: offerTemplate?.id,
            }).then(() => {
                setShouldRefetchItems(true);
            });
        }
    }, [idOffer, plannedOffersRefetch, offerTemplate, isRoomService]);

    useEffect(() =>  {
        refreshItemsAndPlannedOffers();
        // @ts-ignore
    }, [idOffer, plannedOffersRefetch, offerTemplate, plannedOffersPagination, history.location.query, refreshItemsAndPlannedOffers]);



    useEffect(() => {
        if (plannedOffers?.length > 0 && !didChangeFirstDay) {
            const firstDay = getCarouselFirstDay(plannedOffers);
            const carouselLimitToSet = new Date(firstDay);
            carouselLimitToSet.setUTCHours(0, 0, 0, 0);
            const newDateInterval = getDatesIntervalISO(firstDay);

            if (newDateInterval !== plannedOffersPagination.limitDateISO) {
                setPlannedOffersPagination({
                    ...plannedOffersPagination,
                    limitDateISO: newDateInterval,
                });
            }

            setCarouselFirstDay(firstDay);
            setDidChangeFirstDay(true);
        }
    }, [plannedOffers, didChangeFirstDay, plannedOffersPagination]);

    const plannedAndUnavailableOffers = useMemo(() => {
        const isFirstPage = !hasPreviousPage;

        let carouselDate = DateTime.fromJSDate(carouselFirstDay).setZone(offerTemplate?.timeZone || FRANCE_TIMEZONE);
        carouselDate = carouselDate.plus({days: DEFAULT_NUMB_OF_DAYS_DISPLAYED * pageNumber});

        const today = DateTime.utc().setZone(offerTemplate?.timeZone || FRANCE_TIMEZONE).startOf('day');

        const firstOfferDate =
            plannedOffers?.length > 0 ? DateTime.fromISO(getDates(plannedOffers?.[0]?.withdrawRange)[0]).setZone(offerTemplate?.timeZone || FRANCE_TIMEZONE).startOf('day') : today;
        const firstVisibleDay = plannedOffers?.length > 0 ? carouselDate : today;

        const date = DateTime.utc().setZone(offerTemplate?.timeZone || FRANCE_TIMEZONE);

        // we need to check if the first offer date if after the last day in the carousel, so its the current day + DEFAULT_NUMB_OF_DAYS_DISPLAYED (which is 5 at the time of writting)
        // less 1 day to make a total of 5 days (current day + 4)
        const lastDayDisplayedOnFirstRow = DateTime.fromISO(date.toISO()).setZone(offerTemplate?.timeZone || FRANCE_TIMEZONE).plus({days: DEFAULT_NUMB_OF_DAYS_DISPLAYED -1 }).endOf('day');
        const firstDayNotOnFirstPage = firstOfferDate > lastDayDisplayedOnFirstRow;
        const fromDate = isFirstPage ? (firstDayNotOnFirstPage ? firstOfferDate : today) : firstVisibleDay;

        return plannedOffers ? getPlannedAndUnavailableOffers({ plannedOffers, fromDate, isDaysInAdvance: isDaysInAdvance, isRoomService: isRoomService }) : [];
    }, [plannedOffers, isDaysInAdvance, isRoomService, hasPreviousPage, carouselFirstDay, pageNumber]);

    useEffect(() => {
        if (!plannedOffersLoading && plannedOffers?.length > 0 && selectedOffer?.id !== idOffer) {
            const currentOffer = plannedOffers.find(({ id }) => id === idOffer);
            setSelectedOffer(currentOffer);
        }
    }, [plannedOffersLoading, plannedOffers, selectedOffer, idOffer]);

    /**
     * Use fetch api, because graphql is not good on dealing with file download.
     *
     * @param url
     * @param authToken
     */
    const fetchExcelReport = async (url: string, authToken: string) => {
        try {
            const headers = new Headers();
            headers.append('authorization', authToken);

            const report = await fetch(url, {
                headers,
            });
            if (report.status !== 200) {
                toast(<ErrorToast errorMessage="GENERATING_OFFER_REPORT" />);
                return;
            }

            const defaultReportFilename = t(`app:file.dailyOrdersExcelDefaultFilename`);
            const filename =
                extractFilenameFromHeader(report.headers.get('content-disposition')) || defaultReportFilename;

            await triggerBrowserFileDownload(await report.blob(), filename);
        } catch (error) {
            toast(<ErrorToast errorMessage="GENERATING_OFFER_REPORT" />);
        }
    };

    const handleExcelExport = async (reportType: Report) => {
        if (handleExcelReportButtonDebounce !== null) {
            // clear previous timer
            clearTimeout(handleExcelReportButtonDebounce);
            setHandleExcelReportButtonDebounce(null);
        }

        const url = composeExcelReportURL(window.config.API_GRAPHQL_ENDPOINT, idOffer, reportType);

        // trigger excel report generation half a second after the user stops triggering the event
        setHandleExcelReportButtonDebounce(
            setTimeout(async () => {
                await fetchExcelReport(url, getItem(LocalStorageKey.ACCESS_TOKEN) || '');
            }, 500)
        );
    };

    const composeExcelReportURL = (graphqlEndpoint: string, id: string, reportType: Report) => {
        const apiEndpoint = graphqlEndpoint.split('/graphql')[0];
        return `${apiEndpoint}/offer/${decodeEntityId(id)}/report?type=${reportType}`;
    };

    const toggleCatalogModal = (formula) => {
        if (!!formula?.idOfferItemFormula) {
            setFormulasCatalog(formula);
        }else{
            setFormulasCatalog(null);
        }
        setCatalogPanelOpen(!isCatalogPanelOpen);
    };
    const toggleOfferModal = () => {
        setOfferPanelOpen(!isOfferPanelOpen);
    };

    

    const renderOfferParameters = ({
        orderStart,
        orderEnd,
        withdrawStart,
        withdrawEnd,
        withdrawSlotDuration,
        maxOrdersPerSlot,
        withdrawalType,
    }) => {
        return (
                <ParameterCard onClick={toggleOfferModal}>
                    <Title
                        mode="H3"
                        value={t('page:clickcollect.daily-offers.title.parameters')}
                        grow
                        icon={<Icon.Cog color={theme.color.grey[6]} />}
                    >
                        <Icon.SolidArrow />
                    </Title>
                    <Infos>
                        {offerInformationsView(data)}
                    </Infos>
                    <Infos>
                        {withdrawalType === OfferTemplateWithdrawalType.POS_CLICK_SERVE ||
                        withdrawalType === OfferTemplateWithdrawalType.TABLE_SERVICE
                            ? offerParametersSeatClickServeView({ orderStart, orderEnd })
                            : withdrawalType === OfferTemplateWithdrawalType.INSTANT_CLICK_COLLECT
                            ? offerParametersInstantClickAndCollectView({ orderStart, orderEnd })
                            : withdrawalType === OfferTemplateWithdrawalType.CLICK_AND_PICK_UP
                            ? offerParametersPickUpView({ orderStart, orderEnd })
                            : offerParametersDetailsDefaultView({
                                  orderStart,
                                  orderEnd,
                                  withdrawStart,
                                  withdrawEnd,
                                  withdrawSlotDuration,
                                  maxOrdersPerSlot,
                              })}
                    </Infos>
                </ParameterCard>
        );
    };

    const offerParametersSeatClickServeView = ({ orderStart, orderEnd }) => {
        return (
            <OfferInfo name={t('page:clickcollect.daily-offers.label.orderRangeSeatClickAndServe')}>
                {`${getDisplayTime(orderStart)} - ${getDisplayTime(orderEnd)}`}
            </OfferInfo>
        );
    };

    const offerParametersInstantClickAndCollectView = ({ orderStart, orderEnd }) => {
        return <OfferInfo
            name={t('page:clickcollect.daily-offers.label.orderRange')}
        >
            {`${getDisplayTime(orderStart)} - ${getDisplayTime(orderEnd)}`}
        </OfferInfo>
    };

    const offerParametersPickUpView = ({ orderStart, orderEnd }) => {
        return (
            <OfferInfo name={t('page:clickcollect.daily-offers.label.orderRangeClickAndPickup')}>
                {`${getDisplayTime(orderStart)} - ${getDisplayTime(orderEnd)}`}
            </OfferInfo>
        );
    };
    const offerInformationsView = ({
        offer: {   
            offerTemplate: {published, withdrawalType, pos},
        },
    }) => {
        return (
            <>
                <OfferInfo name={t('page:clickcollect.daily-offers.label.type')}>{`${t(
                    `page:clickcollect.daily-offers.types.${parseWithdrawalType(withdrawalType)}`
                )}`}</OfferInfo>
                <OfferInfo name={t('page:clickcollect.daily-offers.label.pos')}>{pos.name}</OfferInfo>
                <OfferInfo name={t('page:clickcollect.daily-offers.label.status')}>{`${t(
                    `schema:offerTemplate.publishedStatus.${published ? 'PUBLISHED' : 'UNPUBLISHED'}`
                )}`}</OfferInfo>
            </>
        );
    };

    const offerParametersDetailsDefaultView = ({
        orderStart,
        orderEnd,
        withdrawStart,
        withdrawEnd,
        withdrawSlotDuration,
        maxOrdersPerSlot,
    }) => {
        return (
            <>
                <OfferInfo name={t('page:clickcollect.daily-offers.label.orderRange')}>
                    {`${getDisplayTime(orderStart)} - ${getDisplayTime(orderEnd)}`}
                </OfferInfo>
                <OfferInfo name={t('page:clickcollect.daily-offers.label.withdrawRange')}>
                    {`${getDisplayTime(withdrawStart)} - ${getDisplayTime(withdrawEnd)}`}
                </OfferInfo>
                <OfferInfo name={t('page:clickcollect.daily-offers.label.withdrawSlotDuration')}>
                    {`${Duration.fromISO(withdrawSlotDuration).as('minutes')} ${t('app:duration.minutes')}`}
                </OfferInfo>
                <OfferInfo name={t('page:clickcollect.daily-offers.label.maxOrdersPerSlot')}>
                    {maxOrdersPerSlot || ''}
                </OfferInfo>
            </>
        );
    };

    const parseWithdrawalType = (withdrawalType) => {
        switch (withdrawalType) {
            case OfferTemplateWithdrawalType.POS_AT_SITE:
                return 'atSite';
            case OfferTemplateWithdrawalType.POS_TAKE_AWAY:
                return 'takeAway';
            case OfferTemplateWithdrawalType.POS_CLICK_SERVE:
                return 'clicknServe';
            case OfferTemplateWithdrawalType.CONNECTED_LOCKERS:
                return 'connectedLockers';
            case OfferTemplateWithdrawalType.INSTANT_CLICK_COLLECT:
                return 'instantClickCollect';
            case OfferTemplateWithdrawalType.TABLE_SERVICE:
                return 'tableService';
            case OfferTemplateWithdrawalType.CLICK_AND_PICK_UP:
                return 'clicknPickUp';
        }
    };

    const handlePlannedOffersPaginationClick = (click: '+' | '-') => {
        let newPlannedOffersPagination: PaginationOptions | undefined;
        let pageNumberTmp = pageNumber;

        if (click === '+') {
            pageNumberTmp = pageNumber + 1;
            newPlannedOffersPagination = {
                after: plannedOffersData?.offerTemplate?.plannedOffers_v2?.pageInfo?.endCursor,
                first: DEFAULT_NUMB_OF_DAYS_DISPLAYED,
            };
        } else if (click === '-') {
            pageNumberTmp = pageNumber > 0 ? pageNumber - 1 : pageNumber;

            // When we press the back arrow we send the 1st cursor (1st offer currently shown in the carousel) to FS
            // FS will then subtract the value passed in "last" from the position and do the request from that cursor
            // We are having an issue where if there was missing a day in between dates it would get the wrong cursor position
            // so it would always be fetching one day after the start of the week, to fix that we pass it as "6" (DAYS_TO_FETCH_WHEN_GOING_BACK)
            // to make sure it brings all the days included in the week time interval
            newPlannedOffersPagination = !plannedOffersData?.offerTemplate?.plannedOffers_v2?.pageInfo?.hasPreviousPage
                ? { first: DAYS_TO_FETCH_WHEN_GOING_BACK }
                : {
                      last: DAYS_TO_FETCH_WHEN_GOING_BACK,
                      before: plannedOffersData?.offerTemplate?.plannedOffers_v2?.pageInfo?.startCursor
                  };
        }
        setPageNumber(pageNumberTmp);

        if (newPlannedOffersPagination) {
            newPlannedOffersPagination = {
                ...newPlannedOffersPagination,
                limitDateISO: getDatesIntervalISO(carouselFirstDay, pageNumberTmp),
            };
        }

        newPlannedOffersPagination && setPlannedOffersPagination(newPlannedOffersPagination);
    };

    const onClickHandler = async (offer) => {
        if (offer.id) {
            setSelectedOffer(offer);
            // add a random number to the url to force the component to re-render
            history.push(`/${idHolding}/cnc/${idPos}/offers/daily-offers/${offer.id}?rs=${Math.random()}`);
        } else if (!offer.disabled) {
            const forDate = getDates(offer?.withdrawRange)[0];
            const res: any = await createOfferFromTemplate({
                variables: { idOfferTemplate: offerTemplate.id, forDate },
            });
            if (res && res.data && res.data.createOfferFromTemplate) {
                await plannedOffersRefetch({
                    first: DEFAULT_NUMB_OF_DAYS_DISPLAYED,
                    idOfferTemplate: offerTemplate.id,
                });
                removeItem(LocalStorageKey.PLANNED_OFFERS_PAGINATION_STATE);
                history.push(`/${idHolding}/cnc/${idPos}/offers/daily-offers/${res.data.createOfferFromTemplate.id}`);
            }
        }
    };

    const getOfferValidity = async (offerId: string,withoutLoading : boolean = false , shouldShowPopin: boolean= true) => {
      let items: TArticleErrorLabels = []
      try {
        setArticleErrorLabelsState(() => ({
          items: [],
          isLoading: !withoutLoading,
        }));
        const res = await client.query<validateOfferWithCashSystem>({
          query: OFFER_CHECK_QUERY,
          variables: { idOffer: offerId },
          fetchPolicy: "no-cache",
        });
        items =
          res.data.validateOfferWithCashSystem.offerItemsNotOpenForReservation.map(
            (i) => ({
              id: i.id,
              cashSystemCode: i.localArticle.cashSystemCode,
              label: i.label,
            }),
          );
          if(!shouldShowPopin){
            if(items.length){
              toggleValidationArticle(!showValidationArticle);
            }
          }else{
            toggleValidationArticle(!showValidationArticle);
          }
        setArticleErrorLabelsState((_state) => ({
          items,
          isLoading: false,
        }));
      } catch (error) {
        console.log(error);
      }
      setArticleErrorLabelsState((state) => ({
        ...state,
        isLoading: false,
      }));
      return items
    };


    const getArticleValidity = (offerId) => {
      return async (articleId) =>{
        const res = await client.query<validateOfferWithCashSystem>({
          query: OFFER_CHECK_QUERY,
          variables: { idOffer: offerId , articlesIds : [articleId] },
          fetchPolicy: "no-cache",
        });
        const items =
          res.data.validateOfferWithCashSystem.offerItemsNotOpenForReservation.map(
            (i) => ({
              id: i.id,
              cashSystemCode: i.localArticle.cashSystemCode,
              label: i.label,
            }),
          );
          return items
      }
    }

    const checkItemsList = async (offerId : string) => {
        await client.query<any>({
            query: OFFER_ITEM_LIST_QUERY,
            variables: {
                after: null,
                before: null,
                first: 100,
                last: null,
                querySearch: [{ key: 'idOffer', operator: '=', value: offerId },{ key: 'toSell', operator: '=', value: "ACTIVATED" }],
            },
            fetchPolicy: 'no-cache',
        })
        .then((res: any) => {
            setIsItemsEmpty(res.data.list.totalCount === 0);
        });
    }

    checkItemsList(idOffer);

    return loading ? (
        <Loader />
    ) : (
        <>
            <OfferParameterFormPanel offer={currentOffer} closeModal={toggleOfferModal} isOpen={isOfferPanelOpen} />
            <Struct.Section>
                <HeaderWrapper>
                    <Title
                        mode="H2"
                        value={offerTemplate.name}
                        subTitle={t('app:display-date', {
                            day: getDayFullName(withdrawStart),
                            date: getDisplayDate(withdrawStart),
                        })}
                        // @todo use router config
                        icon={
                            <Link to={`/${idHolding}/cnc/${idPos}/offers`}>
                                <BackButton />
                            </Link>
                        }
                    >
                        <OfferPublishToggle
                            offer={currentOffer}
                            isDaysInAdvance={currentOffer.offerTemplate?.daysInAdvanceEnd > 0}
                            isRoomService={isRoomService}
                            preCheckOffer={
                                shouldVerifyOfferArticles
                                    ? () => {
                                          return getOfferValidity(idOffer, true, false);
                                      }
                                    : undefined
                            }
                        />
                    </Title>
                </HeaderWrapper>
                <OfferDatesCarousel
                    loading={plannedOffersLoading}
                    activeOfferId={selectedOffer?.id}
                    onSelectOffer={onClickHandler}
                    offers={plannedAndUnavailableOffers}
                    paginationButtons={{
                        hasNextPage,
                        hasPreviousPage,
                        handleNextButtonClick: () => handlePlannedOffersPaginationClick('+'),
                        handlePreviousButtonClick: () => handlePlannedOffersPaginationClick('-'),
                    }}
                    isInAdvanceOfferTemplate={isDaysInAdvance || isRoomService}
                />
                {renderOfferParameters({
                    orderStart,
                    orderEnd,
                    withdrawStart,
                    withdrawEnd,
                    withdrawSlotDuration,
                    maxOrdersPerSlot: currentOffer.maxOrdersPerSlot,
                    withdrawalType: offerTemplate.withdrawalType,
                })}
                <Struct.Card>
                    <Title mode="H3" value={t('page:clickcollect.daily-offers.title.articles')} grow>
                        {offerTemplate?.daysInAdvanceEnd ? (
                            <OfferItemsCloner
                                idOfferTemplate={offerTemplate?.id}
                                idOffer={idOffer}
                                setShouldRefetchItems={setShouldRefetchItems}
                            />
                        ) : (
                            <></>
                        )}
                        {offerTemplate?.isRoomService ? (
                            <ApplyOfferItemsToAll
                                idOfferTemplate={offerTemplate?.id}
                                idOffer={idOffer}
                                refreshItemsAndPlannedOffers={refreshItemsAndPlannedOffers}
                            />
                        ) : (
                            <></>
                        )}
                        {shouldVerifyOfferArticles ? (
                            <Button
                                onClick={async () => {
                                    if (!articleErrorState.isLoading) {
                                        await getOfferValidity(idOffer);
                                    }
                                }}
                                display={ButtonType.SECONDARY}
                                disabled={isItemsEmpty}
                                isLoading={articleErrorState.isLoading}
                            >
                                {t('app:button.check-offer-valid')}
                            </Button>
                        ) : (
                            <></>
                        )}
                        <ExcelReportDropdown
                            isRoomService={isRoomService}
                            buttonLabel={t('app:button.dailyOrdersExcelExport')}
                            idOffer={idOffer}
                            onChange={async ({ value }) => {
                                await handleExcelExport(value as Report);
                            }}
                            options={[
                                ...Object.keys(
                                    offerTemplate.withdrawalType === OfferTemplateWithdrawalType.CLICK_AND_PICK_UP
                                        ? ReportsForPickupPoints
                                        : offerTemplate.isRoomService
                                        ? ReportForRoomService
                                        : Report
                                ).map((e) => ({ value: e, label: t(`app:button.productsReport.${e}`) })),
                            ]}
                        />

                        <Button onClick={toggleCatalogModal}>{t('app:button.edit-articles')}</Button>
                    </Title>
                    <OfferItemTable
                        idOffer={idOffer}
                        // @ts-ignore
                        toggleCatalogModal={toggleCatalogModal}
                        isCatalogPanelOpen={isCatalogPanelOpen}
                        setShouldRefetchItems={setShouldRefetchItems}
                        shouldRefetchItems={shouldRefetchItems}
                        formula={formulasCatalog}
                        showValidationArticle={showValidationArticle}
                        articleErrorState={articleErrorState}
                        toggleValidationArticle={toggleValidationArticle}
                        preCheckArticle={shouldVerifyOfferArticles ? getArticleValidity(idOffer) : undefined}
                        checkItemsList={checkItemsList}
                        onErrorArticle={(items) => {
                            setArticleErrorLabelsState((_state) => {
                                if (items.length) {
                                    toggleValidationArticle(!showValidationArticle);
                                }
                                return {
                                    items,
                                    isLoading: false,
                                };
                            });
                        }}
                    />
                </Struct.Card>
            </Struct.Section>
        </>
    );
}

interface IExcelReportDropdown {
    buttonLabel: string;
    options: { value: string; label: string }[];
    onChange: (value: { value: string; label: string }) => void;
    idOffer: string;
    isRoomService: boolean;
}

const ExcelReportDropdown = ({ buttonLabel, options, onChange, idOffer, isRoomService }: IExcelReportDropdown) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [availableForDownload, setAvailableForDownload] = useState(false);
    const [disabledColor] = useState('#B9B9B9');
    const client = useApolloClient();

    const { refetch } = useQuery(REPORTS_AVAILABLE_FOR_DOWNLOAD, {
        variables: {
            idOffer,
        },
        skip: true, // skipped, because the request is being managed inside the useEffect
    });

    useEffect(() => {
        async function checkIfExcelReportsAreAvailableForDownload() {
            try {
                // fetch data from cache, to avoid unnecessary requests
                const cachedData = client.readQuery({
                    query: REPORTS_AVAILABLE_FOR_DOWNLOAD,
                    variables: {
                        idOffer,
                    },
                });

                const availableForDownload = cachedData && cachedData.reportsAvailableForDownload;
                if (availableForDownload) {
                    setAvailableForDownload(true);
                    return;
                }
            } catch (e) {
                // fails when there is no data in cache
            }
            // Fetch data from server
            const {
                data: { reportsAvailableForDownload },
            } = await refetch();

            if (reportsAvailableForDownload) {
                setAvailableForDownload(true);
            }
        }
        checkIfExcelReportsAreAvailableForDownload();
    }, []);

    const filteredOptions = useMemo(() => {
        if (isRoomService && !availableForDownload) {
            return options.filter((option) => option.value === ReportForRoomService.TOTAL);
        }
        return options;
    }, [isRoomService, availableForDownload, options]);

    return (
        <StyledTooltip
            toolTipContent={!availableForDownload && !isRoomService ? t('page:clickcollect.daily-offers.tooltips.noOrdersYet') : ''}
            styledTooltip={true}
        >
            <ReportDropdownWrapper
                onBlur={() =>
                    setTimeout(() => {
                        // detect when dropdown element loses focus
                        // executing onBlur event only after a few milliseconds to allow the onClick on each Option to be detected and executed
                        setOpen(false);
                    }, 150)
                }
            >
                <ReportButton
                    onClick={() => {
                        setOpen(!open);
                    }}
                    disabled={!availableForDownload && !isRoomService}
                >
                    <ExcelIcon color={!availableForDownload && !isRoomService ? disabledColor : undefined} />
                    {buttonLabel}
                    <ReportArrowWrapper>
                        <Arrow turnUp={open} color={!availableForDownload && !isRoomService ? disabledColor : undefined} />
                    </ReportArrowWrapper>
                </ReportButton>
                {open && (
                    <ReportOptionsWrapper>
                        {filteredOptions.map((op) => {
                            return (
                                <ReportOption
                                    key={op.value}
                                    onClick={() => {
                                        if (onChange) {
                                            onChange(op);
                                        }
                                    }}
                                >
                                    {op.label}
                                </ReportOption>
                            );
                        })}
                    </ReportOptionsWrapper>
                )}
            </ReportDropdownWrapper>
        </StyledTooltip>
    );
};

const ReportButton = styled.button`
    height: ${({ theme }) => theme.dimension.height.element}px;
    width: 250px;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 ${({ theme }) => theme.spacing.s}px;
    box-sizing: border-box;
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    border: ${({ theme }) => `1px solid ${theme.color.common.blue}`};
    background-color: ${({ theme }) => `${theme.color.common.white}`};
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.common.blue};
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    &:active {
        border-radius: ${({ theme }) => theme.borderRadius.s}px;
        border: ${({ theme }) => `1px solid ${theme.color.common.blue}`};
    }
    &:disabled {
        color: #b9b9b9;
        border-color: #b9b9b9;
    }
`;

const ReportDropdownWrapper = styled.div`
    position: relative;
`;

const ReportArrowWrapper = styled.div`
    margin-left: 5px;
`;

const ReportOptionsWrapper = styled.div`
    position: absolute;
    z-index: 1;
    background-color: #FFFFFF;
    width: 100%;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: ${({ theme }) => theme.boxShadow[0]};
`;

const ReportOption = styled.div`
    width: 100%;
    font-size: 16px;
    color: ${({ theme }) => theme.color.input.textColor};
    text-align: left;
    display: flex;
    align-items: center;
    padding-left: 10px;
    height: 40px;
    &:hover {
        background-color: ${({ theme }) => theme.color.input.backgroundColorSelected};
        color: ${({ theme }) => theme.color.common.white};
        border-radius: 4px;
    }
`;

const Infos = styled.div`
    display: flex;
    flex-wrap: wrap;
    & > * {
        margin-right: ${({ theme }) => theme.spacing.s}px;
    }
    margin-bottom: ${({ theme }) => theme.spacing.s}px;
`;

// @ts-ignore
const ParameterCard = styled(Struct.Card)`
    margin-bottom: ${({ theme }) => theme.spacing.s}px;
    cursor: pointer;
`;

const HeaderWrapper = styled.div`
    width: 100%;
`;

export const ExcelIcon = styled(Icon.ExcelExport)`
    margin-right: 3px;
    margin-bottom: 7px;
`;

const StyledTooltip = styled((props) => <Tooltip {...props} />)`
    z-index: 2;
    border-radius: 0;
    margin-top: ${({ theme }) => theme.spacing.xxs}px;
    left: ${({ width, iconSize }) => -(width / 2 - iconSize)}px;
    width: ${({ width }) => width}px;
    ${({ theme }) => `padding: ${theme.spacing.xs}px ${theme.spacing.xs + theme.spacing.xxs}px;`}

    // tooltip Text Styles
    color: ${({ theme }) => theme.color.grey[6]};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: ${({ theme }) => theme.typography.fontSizeXXS}px;
    line-height: ${({ theme }) => theme.typography.lineHeight.XXS}px;
    background-color: white;
    letter-spacing: 0;
    font-weight: 500;
`;

export default withRouter(withTheme(OfferDetailsPage));
