// @ts-nocheck
import React, { Suspense } from 'react';
import { ApolloProvider } from '@apollo/client';
import styled, { ThemeProvider } from 'styled-components';
import { toast } from 'react-toastify';
import 'services/i18n';
import { client } from 'services/apollo/client';
import { appTheme, GlobalStyle } from 'theme';
import Router from 'Router';
import { GlobalLoader } from 'components/Loader';
import { CloseButton } from 'components/Toast';
import 'react-toastify/dist/ReactToastify.css';
import { BaseModalBackground, ModalProvider } from 'styled-react-modal';
import Maintenance from 'pages/Maintenance';

toast.configure({
    hideProgressBar: true,
    toastClassName: 'app-toast',
    className: 'app-toast-container',
    closeButton: <CloseButton />,
});

const FadingBackground = styled(BaseModalBackground)`
    opacity: ${(props) => props.opacity};
    transition: all 0.3s ease-in-out;
`;

const App: React.FC = () => {
    const [maintenance, setMaintenance] = React.useState<boolean>(false);
    const [data, setData] = React.useState<string>('');

    React.useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                operationName: 'getToggleToken',
                variables: {
                    type: 'read',
                },
                query: 'query getToggleToken($type: String!) {\n  getWsToken(type: $type) {\n    access_token\n    message\n    __typename\n  }\n}\n',
            }),
        };

        fetch(window.config.API_GRAPHQL_ENDPOINT, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                const dataResult = JSON.parse(result);
                setData(dataResult);
            })
            .catch((error) => console.log('error', error));
    }, []);

    React.useEffect(() => {
        let timer: any;
        const webSocketURL = window.config.WEBSOCKET_URL;
        const token = data?.data?.getWsToken?.access_token;
        if (webSocketURL && !!token && token !== '') {
            const ws = new WebSocket('wss://' + webSocketURL + '/?token=' + token);


            ws.onopen = () => {
                ws.send(
                    JSON.stringify({
                        action: 'retrieveFeatures',
                        application_id: 'FOODI-BACKOFFICE',
                        environment: window.config.FOODI_ENV || 'LOCAL',
                    })
                );

                timer = setInterval(() => {
                    ws.send(
                        JSON.stringify({
                            action: 'ping',
                        })
                    );
                }, 30000);
            };

            ws.onmessage = (lastMessage: any) => {
                const lastMessageData = JSON.parse(lastMessage.data);
                if (lastMessageData.type === 'RETRIEVE') {
                    const environment = window.config.FOODI_ENV || 'LOCAL';
                    const filterByEnvAndApplication = lastMessageData.data.filter(
                        (d: any) =>
                            d.application_id === 'FOODI-BACKOFFICE' &&
                            d.environment === environment &&
                            d.feature_name === 'SITE_MAINTENANCE'
                    );
                    const isActive = filterByEnvAndApplication?.[0]?.isActive || false;
                    setMaintenance(isActive);
                }
            };

            ws.onerror = (e) => {
                console.log(e);
                ws.close();
            };

            ws.onclose = () => {
                ws.close();
            };
        }

        return () => {
            if (timer) clearInterval(timer);
        };
    }, [data]);

    return (
        <ThemeProvider theme={appTheme}>
            <ModalProvider backgroundComponent={FadingBackground}>
                <ApolloProvider client={client}>
                    <GlobalStyle />
                    {maintenance ? (
                        <Maintenance />
                    ) : (
                        <Suspense fallback={<GlobalLoader />}>
                            <Router />
                        </Suspense>
                    )}
                </ApolloProvider>
            </ModalProvider>
        </ThemeProvider>
    );
};

export default App;
